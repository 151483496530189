@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Lato:wght@900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SFProDisplay";
  src: url("./fonts/SFProDisplay-Regular.woff")
      format("woff"),
    url("./fonts/FontsFree-Net-SFProDisplay-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.slide-in-blurred-right {
  opacity: 0;
}
.slide-in-blurred-left {
  opacity: 0;
}
.App .slide-in-blurred-right {
	-webkit-animation: slide-in-blurred-right 0.8s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-right 0.8s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    opacity: 1 !important;
}
.App .slide-in-blurred-left {
	-webkit-animation: slide-in-blurred-left 0.8s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-left 0.8s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    opacity: 1 !important;
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-7 1:34:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-blurred-right {
    0% {
      -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
              transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-right {
    0% {
      -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
              transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes slide-in-blurred-left {
    0% {
      -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
              transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-left {
    0% {
      -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
              transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }

.body{
  font-family: "SFProDisplay";
};

.roboto{
  font-family: 'Roboto';
}

.d-none,.d-none2,.d-none3{
  display: none;
}

.p-img{
  top: -80px;
  right: 30%;
}

.auth-bg{
  background-image: url(./assets/redblur.png), url(./assets/blueblur.png) !important;
  background-position: top left, bottom right;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-a{
  margin-left: auto;
  margin-right: auto;
}

.ml-a{
  margin-left: auto;
}

.mt-a{
  margin-top: auto;
}
.w-250{
  width: 250px;
}
.w-308{
  width: 308px;
}

.w-45p{
  width: 45%;
}

.min-w-220{
  min-width: 220px;
}

.h-85vh{
  height: 87vh;
}

.h-97vh{
  height: 97vh;
}

.text-as{
  font-size: 14px;
  line-height: 17px;
}

.text-bs{
  font-size: 18px;
  line-height: 21px;
}

.text-cs{
  font-size: 25px;
  line-height: 29px;
}
.text-ds{
  font-size: 25px;
  line-height: 30px;
}
.text-es{
  font-size: 60px;
  line-height: 72px;
}
.text-fs{
  font-size: 30px;
  line-height: 36px;
}
.text-gs{
  font-size: 20px;
  line-height: 24px;
}

.text-hs{
  font-size: 16px;
  line-height: 19px;
}

.text-is{
  font-size: 12px;
  line-height: 14px;
}

.text-js{
  font-size: 10px;
  line-height: 12px;
}

.lh-48{
  line-height: 48px;
}

.br50{
  border-radius: 50%;
}

.br10{
  border-radius: 10px;
}

.br6{
  border-radius: 6px;
}

.br5{
  border-radius: 5px;
}

.br8{
  border-radius: 8px;
}

.active{
  color: #E60026;
}
.inactive{
  color: #959595;
}
.icon-bg{
  background: rgba(230, 0, 38, 0.05);
}
.search{
  width: 100;
}
#input-container {
  position: relative;
}

#input-container > img {
  position: absolute;
  top: 12px;
  left: 15px;
}

#input-container > input {
  padding-left: 40px;
}
/* li.active{
  border-bottom: 1px solid #E60026;
} */

.msg{
  /* position: fixed;
  bottom: 20px; */
  height: calc(70vh - 100px);
  /* transform: translateY(-100%) translateY(58px); */
  transition: all .2s ease-out;
}

.msg-is-minimized{
  transform: translateY(100%) translateY(-58px);
  transition: transform .2s ease-out;
}

.msg-icon-minimized{
  transform: translateY(10%) rotate(180deg);
  transition: transform .2s ease-out;
}

.grpball{
  width: 12px;
  height: 12px;
  margin: 4px auto;
}


.radio {
  height: 24px;
  width: 24px;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #E60026;
}


@media only screen and (min-width: 760px){
    .d-none{
      display: block;
    }
}
@media only screen and (min-width: 1264px){
    .d-none2{
      display: block;
    }
}