.input {
    width: 100%;
}

.input p, .ghjt{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
.input .input-field {
    width: 100%;
    height: 50px;
    padding-left: 11px;
    background: transparent;
    border: 1px solid #727272;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
    outline: none;
}
.input .input-field:focus {
    border: 1px solid #727272;
    transition: all 0.4s;
}
.input .show-password {
    margin-top: 13.5px;
    margin-left: -40px;
}
.input .error-message {
    color: red;
    margin-top: 5px;
    font-size: 11px;
}
.input .disabled {
    background: rgba(232, 232, 232, 0.38) !important;
    color: #9a9ab0 !important;
}
